import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "app/auth/authentication.service";
import { User } from "app/auth/user";
import { ResponsiveService } from "app/common-ux/responsive.service";
import { map, Observable } from "rxjs";
import { TenantDto } from "../tenant-dto";
import { TenantService } from "../tenant.service";

interface TenantWrapper {
    tenant: TenantDto;
    image?: string;
}

@Component({
    templateUrl: "./select-tenant-page.component.html",
    styleUrls: ["./select-tenant-page.component.scss"],
    standalone: false,
})
export class SelectTenantPageComponent {
    public user$: Observable<User | undefined>;
    public isGlobalAdmin$: Observable<boolean>;
    public accessibleTenants$: Observable<TenantWrapper[]>;
    public allTenants$: Observable<TenantWrapper[]>;
    public isMobile$: Observable<boolean>;

    public constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        tenantService: TenantService,
        responsiveService: ResponsiveService,
    ) {
        this.user$ = authenticationService.user$;
        this.isGlobalAdmin$ = authenticationService.user$.pipe(map((u) => !!u?.isGlobalAdmin));
        this.accessibleTenants$ = authenticationService.user$.pipe(
            map((u) => u?.getAccessibleTenants() ?? []),
            this.sortTenants(),
            this.generateWrapper(),
        );
        this.allTenants$ = tenantService
            .getAllTenants()
            .pipe(this.sortTenants(), this.generateWrapper());
        this.isMobile$ = responsiveService.isMobile$;
    }

    public async logout() {
        await this.authenticationService.logout();
        await this.router.navigateByUrl("/login");
    }

    private sortTenants() {
        return map<TenantDto[], TenantDto[]>((tenants) => {
            return tenants.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    private generateWrapper() {
        return map<TenantDto[], TenantWrapper[]>((tenants) => {
            return tenants.map((t) => ({
                tenant: t,
                image: t.logo ?? "/assets/group_placeholder.svg",
            }));
        });
    }
}
